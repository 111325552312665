





















import { Component, Vue, Watch } from "vue-property-decorator";
import _ from "lodash";

@Component({
  components: {},
  props: {
    showDialog: {
      type: Boolean,
    },
    message: {
      type: String,
    },
  },
})
export default class Loading extends Vue {}
