


























































































































































































































































































































import { Vue, Component } from "vue-property-decorator";

import { appName } from "@/env";
import {
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
  readHasAdminAccess,
  readUserProfile,
} from "@/store/main/getters";
import {
  commitSetDashboardShowDrawer,
  commitSetDashboardMiniDrawer,
} from "@/store/main/mutations";
import { dispatchUserLogOut, dispatchSplynxToken } from "@/store/main/actions";
import Header from "../Header.vue";
import Footer from "../Footer.vue";
import _ from "lodash";
import Loading from "../Loading.vue";
import moment from "moment-timezone";

const routeGuardMain = async (to, from, next) => {
  if (to.path === "/main") {
    next("/main/dashboard");
  } else {
    next();
  }
};

@Component({
  components: { Header, Footer, Loading },
})
export default class Main extends Vue {
  public appName = appName;
  public showLoading = false;
  $refs!: Vue["$refs"] & {
    form: HTMLFormElement;
    csrf: HTMLFormElement;
    session: HTMLFormElement;
  };
  public menu = false;
  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  get adminLink() {
    const query = this.$route.query.dev ? "?dev=1" : "";
    return `/main/admin/users/all${query}`;
  }
  get salesLink() {
    const query = this.$route.query.dev ? "?dev=1" : "";
    return `/main/admin/users/sales_report${query}`;
  }
  get MARLink() {
    const query = this.$route.query.dev ? "?dev=1" : "";
    return `/main/admin/users/MAR${query}`;
  }
  get buildingsLink() {
    const query = this.$route.query.dev ? "?dev=1" : "";
    return `/main/admin/users/buildings${query}`;
  }

  get receiptsLink() {
    const query = this.$route.query.dev ? "?dev=1" : "";
    return `/main/admin/users/all_receipts${query}`;
  }

  get promoCostLink() {
    const query = this.$route.query.dev ? "?dev=1" : "";
    return `/main/admin/users/promoCosts${query}`;
  }

  get botlogLink() {
    const query = this.$route.query.dev ? "?dev=1" : "";
    return `/main/admin/users/botlog_report${query}`;
  }
  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store)
    );
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store)
    );
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }

  public async loginForm() {
    this.showLoading = true;
    await dispatchSplynxToken(this.$store, {}).then((response) => {
      var data = _.get(response, "data.data", {});
      this.$refs.csrf.value = data.csrf_token;
      this.$refs.session.value = data.session_id;

      this.$refs.form.submit();
      this.showLoading = false;
    });
  }

  formatDate(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY HH:mm");
    }
    return "";
  }
}
